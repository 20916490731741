// api.js
export const Api = 'https://stage.essecoindustrial.com/graphql';
export const urlAssets = 'https://stage.essecoindustrial.com';

const locale = (document.documentElement.lang || 'en').split('_')[0].split('-')[0];
const supportedLocales = ['it', 'en', 'es', 'de', 'fr'];
const finalLocale = supportedLocales.includes(locale) ? locale : 'en';

export { finalLocale as locale };


export const SlugCommercialFilter = 'commercial-sites';
export const SlugApplicationFilter = 'applications';