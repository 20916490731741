import $ from 'jquery';
import Component from '../models/Component';

export default class Brevetto extends Component {
    constructor(element, context) {
        super(element, context);
        const el = this.element;
        this.element.on('click', function(){
          const icon = el.find('.icon-shape');
          icon.toggleClass('active');
          const answer = el.find('.answercont2').length ? el.find('.answercont2') : el.nextAll('.answercont2').first();
          $(answer).fadeToggle();
        });
    }
}