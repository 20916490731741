import SwiperSlider from "../components/abstract/SwiperSlider";
import $ from 'jquery';

export default class SliderApplications extends SwiperSlider {
 constructor(element, context) {
  super(element, context);

  const observer = new MutationObserver(() => {
   if (this.element[0].querySelectorAll('.swiper-slide').length > 0) {
    observer.disconnect(); // Disconnetti l'observer per evitare esecuzioni multiple
    this.initializeSlider();
   }
  });

  observer.observe(this.element[0], {
   childList: true, // Osserva i cambiamenti nei figli diretti
   subtree: true,   // Osserva anche i cambiamenti nei nodi discendenti
  });
 }

 initializeSlider() {
  this.options = {
   initialSlide: this.element.data('last') ? this.element[0].querySelectorAll('.swiper-slide').length : 1,
   pagination: {
    el: this.element.find('.swiper-pagination')[0],
   },
   navigation: {
    nextEl: this.element.find('.swiper-button-next')[0],
    prevEl: this.element.find('.swiper-button-prev')[0],
   },
   spaceBetween: 10,
   slidesPerView: 1,
   breakpoints: {
    1024: {
     slidesPerView: 2,
     spaceBetween: 30,
    },
    1224: {
     slidesPerView: 3,
     spaceBetween: 50,
    },
   },
   centeredSlides: false,
   loop: false,
  };

  this.init(); // Avvia l'inizializzazione dello slider
 }
}
