import $ from 'jquery';
import {
 Api,
} from './catalogoprodotti/Api';

export default class Mediacontacts {
  constructor(element, context) {
    this.element = element;
    this.context = context;
    this.Api = Api;
    this.init();
  }

  init() {
    const documentType = $(this.element).data('type');
    const urlDownloadPdf = $(this.element).data('href');
    const locale = (document.documentElement.lang || 'en').split('_')[0].split('-')[0];
    const supportedLocales = ['it', 'en', 'es', 'de', 'fr'];
    const finalLocale = supportedLocales.includes(locale) ? locale : 'en';

    this.form = {
      name: '',
      surname: '',
      company: '',
      email: '',
      phone_number: '',
      document_type: documentType,
      locale: finalLocale,
    };
    
    this.urlDownloadPdf = urlDownloadPdf;
    this.bindEvents();

  }

  bindEvents() {
    $('#downloadForm').on('submit', (e) => this.submitForm(e));
    $('#closeForm').on('click', () => this.closeForm());
    $(this.element).on('click', () => this.showForm());
  }

  showForm() {
   $('.form-contacts').fadeIn();
   $('body').css('overflow', 'hidden');
 }
 
 closeForm() {
   $('.form-contacts').fadeOut();
   $('body').css('overflow', '');
 }
 

  async submitForm(event) {
    event.preventDefault();
    this.clearErrors();

    this.form.name = $('#name').val();
    this.form.surname = $('#surname').val();
    this.form.company = $('#company').val();
    this.form.email = $('#email').val();
    this.form.phone_number = $('#phone_number').val();

    if (!this.validateForm()) {
      return;
    }

    const mutation = `
      mutation {
        createCustomer(
          name: "${this.form.name}",
          surname: "${this.form.surname}",
          company: "${this.form.company}",
          email: "${this.form.email}",
          phone_number: "${this.form.phone_number}",
          document_type: "${this.form.document_type}",
          locale: "${this.form.locale}"
        ) {
          name
        }
      }
    `;
    try {
      const response = await fetch(this.Api, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: mutation }),
      });

      const result = await response.json();

      if (result.data && result.data.createCustomer) {
        this.closeForm();
        window.open(this.urlDownloadPdf, '_blank');
      } else {
        this.showErrorGeneral();
      }
    } catch (error) {
      this.showErrorGeneral();
    }
  }

  validateForm() {
    let isValid = true;

    if (!this.form.name) {
      $('#errorName').text('Il nome è obbligatorio');
      isValid = false;
    }
    if (!this.form.surname) {
      $('#errorSurname').text('Il cognome è obbligatorio');
      isValid = false;
    }
    if (!this.form.company) {
      $('#errorCompany').text('L\'azienda è obbligatoria');
      isValid = false;
    }
    if (!this.form.email) {
      $('#errorEmail').text('L\'email è obbligatoria');
      isValid = false;
    }
    if (!this.form.phone_number) {
      $('#errorPhoneNumber').text('Il numero di telefono è obbligatorio');
      isValid = false;
    }

    return isValid;
  }

  clearErrors() {
    $('.error').text('');
    $('#errorGeneral').hide();
  }

  showErrorGeneral() {
    $('#errorGeneral').show();
  }
}